import React, { useState, Suspense, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import './css/ShopifyPortal.css';
import { OrbitControls, Html, Environment, useGLTF, useProgress, PresentationControls } from "@react-three/drei";
import { Canvas, useThree } from '@react-three/fiber';
import cube_glb from '../assets/dresses/cube.glb';
import { requestHandlerFunction } from "../lib/RequestHandler";
import { BASE_URL, NEW_BASE_URL } from "../lib/common";
import DownloadStatus from "../lib/DownloadStatus";
import { useControls } from "leva";
import { DirectionalLightHelper, DirectionalLight, NoToneMapping, SpotLight, SpotLightHelper, AmbientLight, DoubleSide } from "three";

import harbor_hdri from "../assets/hdri/Harbor_Env.hdr";
import Light_4_hdri from "../assets/hdri/Light_4.hdr";
import studio_medium_contrast_hdri from "../assets/hdri/Medium_Contrast.hdr";
import natural_2_hdri from "../assets/hdri/natural_2.hdr";
import sky_hdri from "../assets/hdri/Sky_Cloudy_Env.hdr";
import studio_vivid from "../assets/hdri/Studio_Vivid.hdr";
import moment from "moment";

const ShopifyPortal2 = () => {
    const effectRan = useRef(false);
    // const storeList = ['SBVStore', 'Flirtatious', 'SBVMVP'];
    // const storeObj = {
    //     'SBVStore': { 'SBV-Shirts': ['SBV-S1', 'SBV-S2', 'SBV-S3'], 'SBV-Pants': ['SBV-P1', 'SBV-P2', 'SBV-P3'], 'SBV-Shoes': ['SBV-S1', 'SBV-S2', 'SBV-S3'] },
    //     'Flirtatious': { 'F-Shirts': ['F-S1', 'F-S2', 'F-S3'], 'F-Pants': ['F-P1', 'F-P2', 'F-P3'], 'F-Shoes': ['F-S1', 'F-S2', 'F-S3'] },
    //     'SBVMVP': { 'MVP-Shirts': ['MVP-S1', 'MVP-S2', 'MVP-S3'], 'MVP-Pants': ['MVP-P1', 'MVP-P2', 'MVP-P3'], 'MVP-Shoes': ['MVP-S1', 'MVP-S2', 'MVP-S3'] }
    // };
    const [storeList, setStoreList] = useState(null);
    const [storeDetails, setStoreDetails] = useState(null);
    const [storeName, setStoreName] = useState(null);
    const [collectionName, setCollectionName] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const prodInfoRef = useRef(null);
    const [productHandle, setProductHandle] = useState(null);
    const [file, setFile] = useState(null);
    const formData = new FormData();
    const [modelUrl, setModelUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [envName, setEnvName] = useState("Medium Contrast");
    const [envExposure, setEnvExposure] = useState(0.5);
    const [chosenFileSize, setChosenFileSize] = useState(null);
    const [fileSizeColor, setFileSizeColor] = useState('none');
    const [toggleAlert, setToggeAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');
    const [useEnvHdri, setUseEnvHdri] = useState(false);

    const envObj = {
        "Sky": { hdri: sky_hdri, exp: 0.6, env_name: "Sky" },
        "Harbour": { hdri: harbor_hdri, exp: 2, env_name: "Harbour" },
        "Natural": { hdri: natural_2_hdri, exp: 1, env_name: "Natural" },
        "Studio Lights": { hdri: Light_4_hdri, exp: 0.35, env_name: "Studio Lights" },
        "Medium Contrast": { hdri: studio_medium_contrast_hdri, exp: 0.35, env_name: "Medium Contrast" },
        "Studio Vivid": { hdri: studio_vivid, exp: 0.35, env_name: "Studio Vivid" },
    };

    function onStoreChange(event) {
        setStoreName(event.target.value);
        setCollectionName(null);
        setProductInfo(null);
        setProductHandle(null);
        setModelUrl(null);
        setChosenFileSize(null);
        setFile(null);
        if (event.target.value !== null) {
            fetchStoreDetails(event.target.value)
                .then(function (response) {
                    console.log(response[0].data.data.Collections);
                    console.log(response);
                    setStoreDetails(response[0].data.data.Collections);
                }).catch(function (error) {
                    console.log(error);
                });
            fetchStoreConfigEnvSettings(event.target.value)
                .then(function (response) {
                    console.log(response[0].data.data);
                }).catch(function (error) {
                    console.log(error);
                });
        }
        console.log(storeName);
    }
    function onCollectionChange(event) {
        setCollectionName(event.target.value);
        setProductInfo(null);
        setProductHandle(null);
        setModelUrl(null);
        setChosenFileSize(null);
        setFile(null);
        console.log(event.target.value);
    }
    function onProductChange(event) {
        setFile(null);
        setModelUrl(null);
        setChosenFileSize(null);
        const handle = event.target.value;
        setProductHandle(event.target.value);
        const filteredCollection = storeDetails.filter(collection => collection.collection_name === collectionName);
        const products = filteredCollection[0].products;
        const product_info = products.filter(product => product.product_handle === handle);
        setProductInfo(product_info[0]);
        prodInfoRef.current = product_info[0];
        console.log(product_info[0]);
        if (product_info[0] === null || product_info[0] === undefined) {
            setModelUrl(null);
            setChosenFileSize(null);
        }
        try {
            fetch3dModel(product_info[0].product_handle).then(function (resp) { console.log(resp) });
            fetchEnvSettings(product_info[0].product_handle)
                .then(function (resp) {
                    console.log(resp.data);
                })
                .catch(function (error) { console.log(error) });
        }
        catch { }

    }
    function onFileUpload(event) {
        const myFile = event.target.files[0];

        if (myFile.name.includes(' ')) {
            alert("Filename contains 'space' please remove and try again.");
            event.target.value = '';
            return;
        }

        const modifiedFile = new File([myFile], myFile.name, { type: 'model/gltf-binary' });
        if (myFile.name.endsWith('.glb')) {
            const size = Math.round(myFile.size / 1024) / 1000;
            console.log(size);
            if (size > 25) {
                showAlert('WARNING: File size is greater than 25 MB', 'red', 4000);
                setFileSizeColor('red');
            }
            else if (size > 10) {
                showAlert('WARNING: File size is greater than 10 MB', 'yellow', 4000);
                setFileSizeColor('yellow');
            } else setFileSizeColor('white');
            setChosenFileSize(size);
            setFile(modifiedFile);
            let modifiedFileUrl = URL.createObjectURL(modifiedFile);
            setModelUrl(modifiedFileUrl);
        } else {
            alert('Only ".glb" files are allowed');
            event.target.value = '';
        }
    }
    function onEnvNameChange(event) {
        setEnvName(event.target.value);
    }
    function onEnvExposureChange(event) {
        setEnvExposure(Math.round(event.target.value * 100) / 100);
    }

    function fetchStoreList() {
        //Function to fetch list of stores from server through RequestHandler.js
        return new Promise(function (resolve, reject) {
            console.log("BASE_URL ---> ", BASE_URL);
            const url = BASE_URL + "sbv/support/getStoreList";
            requestHandlerFunction(url, "getStoreList", "get", "json")
                .then(function (resp) {
                    resolve(resp);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    };

    function fetchStoreDetails(storeName) {
        //Function to fetch selected store details from server through RequestHandler.js
        return new Promise(function (resolve, reject) {
            const url = BASE_URL + "sbv/support/getStoreDetails";
            const my_data = {
                store_name: storeName
            }
            requestHandlerFunction(url, "getStoreDetails", "get", "json", my_data)
                .then(function (resp) {
                    resolve(resp);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    };

    function fetchStoreConfigEnvSettings(storename) {
        return new Promise(function (resolve, reject) {
            const url = BASE_URL + "sbv/support/getStoreConfig";
            const my_data = {
                store_name: storename
            }
            requestHandlerFunction(url, "getStoreConfig", "get", "json", my_data)
                .then(function (resp) {
                    console.log(resp[0].data.data);
                    setUseEnvHdri(resp[0].data.data.isHDRLighting);
                    setEnvName(resp[0].data.data.defaultEnvName);
                    setEnvExposure(resp[0].data.data.defaultEnvExposure);
                    resolve(resp);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    function updateStoreConfigEnvSettings() {
        return new Promise(function (resolve, reject) {
            const url = BASE_URL + "sbv/support/UpdateStoreConfigEnvSettings";
            if (storeName === null || storeName === undefined) return;
            const my_data = {
                store_name: storeName,
                env_name: envName,
                env_exposure: envExposure
            }
            requestHandlerFunction(url, "UpdateStoreConfigEnvSettings", "get", "json", my_data)
                .then(function (resp) {
                    showAlert('SUCCESS: Environment settings saved to server', 'green');
                    resolve(resp);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    function fetch3dModel(productHandle = productInfo.product_handle) {
        //Function to fetch Avatar GLB from server through RequestHandler.js
        return new Promise(function (resolve, reject) {
            const url = NEW_BASE_URL + "get3DModel";
            const myData = {
                store_name: storeName,
                collection_name: collectionName,
                product_handle: productHandle,
                time_stamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
            };
            requestHandlerFunction(url, "get3DModel", "get", "arraybuffer", myData)
                .then(function (r) {
                    const response = r[0];
                    const request_id = r[1];
                    const file = new Blob([response.data], { type: "model/gltf+binary" });

                    const size = Math.round(file.size / 1024) / 1000;
                    if (size > 25) {
                        showAlert('WARNING: File size is greater than 25 MB', 'red', 4000);
                        setFileSizeColor('red');
                    }
                    else if (size > 10) {
                        showAlert('WARNING: File size is greater than 10 MB', 'yellow', 4000);
                        setFileSizeColor('yellow');
                    } else setFileSizeColor('white');
                    setChosenFileSize(size);

                    // creating an object URL that can be used to load the file into the scene
                    console.log(productHandle, prodInfoRef.current.product_handle);
                    if (productHandle === prodInfoRef.current.product_handle) setModelUrl(URL.createObjectURL(file));
                    console.log("Model URL: " + modelUrl);
                    DownloadStatus.setStatus("getAvatar", 100, request_id);
                    console.log(response);
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Model not loaded');
                    console.log(error);
                    setModelUrl('NA');
                    setModelUrl('NA');
                    setChosenFileSize(null);
                });
        });
    };

    function fetchEnvSettings(productHandle) {
        return new Promise(function (resolve, reject) {
            const url = BASE_URL + "sbv/support/getEnvSettings";
            const myData = {
                store_name: storeName,
                collection_name: collectionName,
                product_handle: productHandle,
            };
            requestHandlerFunction(url, "getEnvSettings", "get", "json", myData)
                .then(function (r) {
                    const response = r[0];
                    const request_id = r[1];
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Env Data not received');
                    reject(error);
                });
        });
    }

    function upload3dModel() {
        if (productInfo === null || file === null) {
            alert("Enter the details and select a '.glb' file to upload");
            return;
        }
        setUploading(true);
        const env = {};
        // const url = BASE_URL + "sbv/support/upload3DModel";
        const url = NEW_BASE_URL + "sbv/admin/upload3DModel";
        formData.append('product_handle', productInfo.product_handle);
        formData.append('store_name', storeName);
        formData.append('collection_name', collectionName);
        formData.append('file', file);
        formData.append('env', JSON.stringify(env));
        console.log(env);
        console.log(file);
        console.log(productInfo.product_handle);
        console.log(formData.get('file'));
        requestHandlerFunction(url, "upload3DModel", "post", "string", formData)
            .then(function (resp) {
                console.log(resp);
                document.getElementById("myfile").value = "";
                setFile(null);
                setUploading(false);
                fetch3dModel().then(function (resp) { console.log(resp) }.catch(function (error) { console.log(error) }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (effectRan.current === false) {
            fetchStoreList()
                .then(function (response) {
                    console.log(response[0].data.data);
                    setStoreList(response[0].data.data);
                }).catch(function (error) {
                    console.log(error)
                });
            return () => {
                effectRan.current = true;
            };
        }
    }, [])

    function StoreSelector() {
        if (storeList !== null) {
            return (
                <>
                    <option value={null}>Choose a store</option>
                    {storeList.map((store, i) => { return <option key={i} value={store}>{store}</option> })}
                </>
            );
        }
    }
    function CollectionSelector() {
        try {
            return (
                <>
                    <option value={null}>Choose a collection</option>
                    {storeDetails.map((collection, i) => { return <option key={i} value={collection['collection_name']}>{collection['collection_name']}</option> })}
                </>
            )
        } catch { }
    }
    function ProductSelector() {
        try {
            const filteredCollection = storeDetails.filter(collection => collection.collection_name === collectionName);
            const products = filteredCollection[0].products;
            return (
                <>
                    <option value={null}>Choose a product</option>
                    {products.map((product, i) => { return <option key={i} value={product.product_handle}>{product.product_handle}</option> })}
                </>
            )
        } catch { }

    }

    function ProductImageView() {
        if (collectionName !== null && collectionName !== undefined && productHandle !== null && productHandle !== undefined) {
            const filteredCollection = storeDetails.filter(collection => collection.collection_name === collectionName);
            const products = filteredCollection[0].products;
            const currentProduct = products.filter(product => product.product_handle === productHandle)[0];
            let imageArray = [];
            try { currentProduct.product_images.map(image => { imageArray.push(image) }); }
            catch { }
            return (
                <div id="carouselExampleControls" className="carousel slide carouselCont" data-ride="carousel">
                    <div className="carousel-inner">
                        {imageArray.map((images, index) => {
                            return (
                                <div className={(index === 0) ? "carousel-item active" : "carousel-item"} id="imgholder" key={images.image_url} >
                                    <img className="carouselImg" src={images.image_url} alt={images.image_name} />
                                    <p >{images.image_name}</p>
                                </div>
                            );
                        })}
                    </div>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span></a>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span></a>
                </div>
            );
        }
        return (
            <div src="..." className="carouselCont" style={{ backgroundColor: 'gray', color: "white", fontSize: "1.5rem" }}><p>Please select a product to view images</p></div>
        );
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={{ backgroundColor: alertColor }}>{alertMessage}</div>}
            </>
        )
    }
    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggeAlert(true);
        setTimeout(function () { setToggeAlert(false) }, time)
    }

    function Env() {
        return <Environment files={envObj[envName].hdri} />;
    }

    const addDirectionalLight = (x, y, z, i, color, target = [0, 0, 0]) => {
        const light = new DirectionalLight(color, i);
        light.position.set(x, y, z);
        light.target.position.set(target[0], target[1], target[2]);
        const helper = new DirectionalLightHelper(light, 1, 'black');
        helper.position.set(x, y, z);
        return { light: light, helper: helper };
    };
    function addSpotLight(x, y, z, i, color, target = [0, 0, 0], angle = Math.PI / 3) {
        const light = new SpotLight(color, i);
        light.position.set(x, y, z);
        light.angle = angle;
        light.target.position.set(target[0], target[1], target[2]);
        const helper = new SpotLightHelper(light, 'red');
        return { light: light, helper: helper };
    }
    function addAmbientLight(i) {
        const ambientLight = new AmbientLight(0xffffff, i); // Parameters: color, intensity
        return ambientLight;
    }
    function Lighting() {
        const { light: lightKey, helper: helperKey } = addDirectionalLight(3, 6.5, 8.5, 1, 'white', [0, -1, 0]);
        const { light: lightFill, helper: helperLightFill } = addDirectionalLight(-4, 1.75, 5.5, 0.2, 'white');
        const { light: lightBackLeft, helper: helperBackLeft } = addDirectionalLight(-4, 3.3, -5.5, 0.3, 'white', [0, 1.6, 0]);
        const { light: lightFrontRight, helper: helperFrontRight } = addSpotLight(2, -1.5, 2.5, 0.2, 'white');
        const { light: lightFace, helper: helperFace } = addDirectionalLight(0.5, 0.5, 0.5, 0.1, 'white', [0, 1.2, 0]);
        return (
            <>
                <primitive object={lightKey} />
                <primitive object={lightFill} />
                <primitive object={lightBackLeft} />
                <primitive object={lightFrontRight} />
                <primitive object={lightFace} />

                {/* <primitive object={helperKey} /> */}
                {/* <primitive object={helperLightFill} /> */}
                {/* <primitive object={helperBackLeft} /> */}
                {/* <primitive object={helperFrontRight} /> */}
                {/* <primitive object={helperFace} /> */}
            </>
        )
    }

    function Loader1() {
        //Component to render the loading progress of 3d models
        const { active, progress, errors, item, loaded, total } = useProgress();
        return (
            <Html center style={{ color: "black" }}>{Math.round(progress)} % loaded</Html>
        );
    }

    function SpawnGarment() {
        let finalUrl = cube_glb;
        if (modelUrl !== null && modelUrl !== undefined && modelUrl !== 'NA') finalUrl = modelUrl;
        const gltf = useGLTF(finalUrl);
        const { nodes } = gltf;
        adjustMaterialProperties(nodes);
        return (
            // <mesh position={(collectionName === 'SBV-Iterables-Lower') ? [0, -2.4, 0] : [0, -3.6, 0]} scale={2.8}>
            <mesh position={(collectionName === 'SBV-Iterables-Lower') ? [0, -2.4, 0] : [0, 0, 0]} scale={2.8}>
                <primitive object={gltf.scene} />
            </mesh>
        );
    }

    const adjustMaterialProperties = (nodes) => {
        Object.values(nodes).forEach((node) => {
            // console.log('===========================>>>Name:', node.name);
            if (node.material) {
                // console.log('Material:', node.material);
                // console.log('Metallicness:', node.material.metalness);
                // console.log('Roughness:', node.material.roughness);
                // console.log('clearcoat:', node.material.clearcoat);
                // console.log('clearcoatRoughness:', node.material.clearcoatRoughness);
                // console.log('Normal Map:', node.material.normalMap);
                // console.log('Opacity:', node.material.opacity);
                // console.log('Opacity - Side:', node.material.side);
                // console.log('Opacity - isDoubleSide:', node.material.side === DoubleSide);
                // console.log('Transparent:', node.material.transparent);
                // console.log('gammaFactor:', node.material.gammaFactor);
                // console.log('Color:', node.material.color);
                // console.log('Vertex Shader:', node.material.vertexShader);
                // console.log('Fragment Shader:', node.material.fragmentShader);
                if (useEnvHdri && node.isMesh) {
                    node.material.envMapIntensity = envExposure;
                }
                node.material.side = DoubleSide;
                if (node.name !== null && node.name !== undefined && node.name.startsWith('Trim') && node.isMesh) {
                    node.material.metalness = 0.7;
                    node.material.roughness = 0.20;
                    node.material.clearcoat = 1;
                    // console.log('New Metallicness:', node.material.metalness);
                    // console.log('New roughness:', node.material.roughness);
                    // console.log('New clearcoat:', node.material.clearcoat);
                }
            }
        });
    };

    return (
        <div className='ShopifyPortal'>
            <div className='content'>
                <div className="selector">
                    <label htmlFor="store-name">StoreName</label>
                    <Form.Select size="sm" value={storeName} onChange={onStoreChange} name="store-name" id="store-name">
                        <StoreSelector />
                    </Form.Select>
                    <label htmlFor="collection-name">CollectionName</label>
                    <Form.Select size="sm" value={collectionName} onChange={onCollectionChange} name="collection-name" id="collection-name">
                        <CollectionSelector />
                    </Form.Select>
                    <label htmlFor="product-name">ProductName</label>
                    <Form.Select size="sm" value={productHandle} onChange={onProductChange} name="product-name" id="product-name">
                        <ProductSelector />
                    </Form.Select>
                </div>

                <div className='product-view'>
                    <ProductImageView />
                    <Canvas
                        camera={{ fov: 30, position: [0, 1, 4.5], far: 50 }} className="canvas"
                        gl={{ toneMapping: 0 }}
                    >
                        {!useEnvHdri && <Lighting />}
                        <Suspense fallback={<Loader1 />}>
                            <PresentationControls
                                enabled={true} // the controls can be disabled by setting this to false
                                global={true} // Spin globally or by dragging the model
                                cursor={true} // Whether to toggle cursor style on drag
                                snap={false} // Snap-back to center (can also be a spring config)
                                speed={1} // Speed factor
                                zoom={1} // Zoom factor when half the polar-max is reached
                                rotation={[0, 0, 0]} // Default rotation
                                polar={[-Math.PI / 2, Math.PI / 2]} // Vertical limits
                                azimuth={[-Infinity, Infinity]} // Horizontal limits
                                config={{ mass: 0, tension: 0, friction: 0 }} // Spring config
                            >
                                <SpawnGarment />
                            </PresentationControls>
                            {useEnvHdri && < Env />}
                        </Suspense>
                        <Html><p>{(modelUrl === 'NA') ? 'Model Not available on server' : ''}</p></Html>
                        {/* <OrbitControls enablePan={true} enableDamping={true} enableRotate={true}
                     minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI - Math.PI / 2} /> */}
                        <OrbitControls enablePan={true} enableDamping={false} enableRotate={false} enableZoom={true} />
                    </Canvas>
                </div>

                {
                    (prodInfoRef.current != null) && <>
                        <div className="upload">
                            <Form.Label className="m-1" for="myfile">Select a file, only '.glb' files:</Form.Label>
                            <div className="m-1 d-flex" >
                                <Form.Control className="fileInput" type="file" accept=".glb" id="myfile" name="myfile" onChange={onFileUpload} />
                                <Button size="sm" variant="secondary" onClick={upload3dModel} disabled={(uploading === false) ? false : true}>
                                    {(uploading === false) ? 'Upload' : 'Uploading'}
                                </Button>
                            </div>
                            <Form.Label className="m-1" for="myfile" style={{ backgroundColor: fileSizeColor, color: (fileSizeColor != 'none') ? "black" : "unset" }}>File size: {chosenFileSize} MB</Form.Label>
                        </div>

                        <div className="environment">
                            <div className="m-1 d-flex">
                                <Form.Label for="env-name">Environment</Form.Label>
                                <Form.Select size="sm" value={envName} onChange={onEnvNameChange} name="env-name" id="env-name">
                                    {Object.keys(envObj).map((env, i) => { return <option key={i} value={env}>{env}</option> })}
                                </Form.Select>
                            </div>
                            <div className="m-1 d-flex">
                                <Form.Label for="env-exposure">Environment Exposure:</Form.Label>
                                <input type='range' min={0} max={3} step={0.0001} value={envExposure} onChange={onEnvExposureChange} name="env-exposure" id="env-exposure" />
                                <Form.Label for="env-exposure">{envExposure}</Form.Label>
                                {/* <label htmlFor="env-exposure" style={{ marginLeft: '25px', width: '25px' }}>{envExposure}</label> */}
                            </div>
                            <Button className="m-1 envBtn" size="sm" variant="secondary" onClick={updateStoreConfigEnvSettings}>
                                Save store environment settings to server
                            </Button>
                        </div>
                    </>
                }
            </div>
            <Alerts />
        </div >
    );
};

export default ShopifyPortal2;