import axios from "axios";
import DownloadStatus from "./DownloadStatus";
import logger from "./logger";
import moment from 'moment';

let requestList = []; //List that holds all requests
let reqSeqNo = 0;
const progName = "RequestHandler";

const urlSuffixes = ['/getDress', "/getAvatar", "/createAvatar", "/signup-data", "/user-login", "/getStoreList", "/getStoreDetails", "/upload3DModel", "/get3DModel", "/reload", "/getEnvSettings", "/getStoreConfig", "/UpdateStoreConfigEnvSettings"];

// return status less than 20 - False
// return status more than 20 - True


function internetSpeedDownloadControl(url, requestType, requestPurpose, payload) {
  if (requestList.length === 0) {
    return 21;
  }
  let max_parallel_requests = 1;
  let max_parallel_download = 1;
  if (DownloadStatus.getInternetSpeed() === 1) {
    max_parallel_requests = 1;
  }
  if (DownloadStatus.getInternetSpeed() === 2) {
    logger("preSubmissionRules: Internet Speed Medium", 'RequestHandler')
    max_parallel_requests = 3;
    max_parallel_download = 2;
  }
  if (DownloadStatus.getInternetSpeed() === 3) {
    max_parallel_requests = 4;
    max_parallel_download = 3;
  }

  if (requestList.length >= max_parallel_requests) {
    logger("preSubmissionRules: Only " + max_parallel_requests +
      "parallel requests are allowed for your internet speed = " +
      DownloadStatus.getInternetSpeed(), 'RequestHandler')
    return 0;
  }

  if (calculateDownloadRequestCt >= max_parallel_requests) {
    logger("preSubmissionRules: Only " + max_parallel_download +
      "parallel downloads are allowed for your internet speed = " +
      DownloadStatus.getInternetSpeed(), 'RequestHandler')
    return 0;
  }
}

function calculateDownloadRequestCt() {
  let nextReq = null;
  let next_url = "";
  let ct_dl = 0;
  for (let i = 0; i < requestList.length; i++) {
    nextReq = requestList[i];
    next_url = nextReq['url'];
    if (next_url.endsWith("/getDress") || next_url.endsWith("/getAvatar")) {
      ct_dl += 1;
    }
  }
  return ct_dl;
}

function requestTypeControl(url, requestType, payload) {
  let parts = url.split("/");
  const curr_requestPurpose = parts[parts.length - 1];
  logger("requestTypeControl: Speed=" + DownloadStatus.internetSpeed +
    ", kbps = " + DownloadStatus.avgSpeed.toFixed(2) + ", RequestPurpose=" +
    curr_requestPurpose, 'RequestHandler');
  if (curr_requestPurpose === "createAvatar") {
    for (let i = 0; i < requestList.length; i++) {
      let nextReq = requestList[i];
      parts = nextReq["url"].split("/");
      let next_requestPurpose = parts[parts.length - 1];
      logger("requestTypeControl: next_requestPurpose=" + next_requestPurpose, 'RequestHandler');
      if (next_requestPurpose === "getAvatar") {
        logger("requestTypeControl: Internet too slow, not ready for CreateAvatar yet.", progName);
        return false;
      }
    }
  }
  logger("requestTypeControl: Returning True 1", 'RequestHandler');
  return true;
}

const preSubmissionRules = (url, requestType, requestPurpose, payload) => {
  const requestTypeOk = requestTypeControl(url, requestType, payload);
  if (requestTypeOk) {
    return true;
  } else if (!requestTypeOk) {
    return false;
  }
  const internetSpeedChecksOk = internetSpeedDownloadControl(url, payload);
  if (internetSpeedChecksOk) {
    return true;
  } else if (!internetSpeedChecksOk) {
    return false;
  }
};

function checkRequest(url, payload, requestPurpose, requestType, responseType) {
  if (!isRequestInList(url, requestType, payload)) {
    if (!preSubmissionRules(url, responseType, requestPurpose, payload)) {
      logger("checkRequest: Pre-Submission checks failed.", progName);
      return false;
    }
  } else {
    logger("checkRequest: Request already in queue", progName);
    return false;
  }
  logger("checkRequest: Success", progName);
  return true;
}

function setProgressHandler(payload, req_id) {
  let requestPurpose = payload["requestPurpose"];
  if (requestPurpose === "getAvatar") {
    DownloadStatus.setStatus(requestPurpose, 0, req_id);
    payload["onDownloadProgress"] = function (progressEvent) {
      if (DownloadStatus.skip < 40) {
        DownloadStatus.setSkip(1);
        return;
      }
      DownloadStatus.setDLArrays(req_id, requestPurpose, moment(), progressEvent.loaded);
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      DownloadStatus.setStatus(requestPurpose, progress, req_id);
      DownloadStatus.setSkip(1);
    };
  } else if (requestPurpose === "getDress") {
    DownloadStatus.setStatus(payload.dress_id, 0, req_id);
    payload["onDownloadProgress"] = function (progressEvent) {
      if (DownloadStatus.skip < 40) {
        DownloadStatus.setSkip(1);
        return;
      }
      DownloadStatus.setDLArrays(req_id, payload.dress_id, moment(), progressEvent.loaded);
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      DownloadStatus.setStatus(payload.dress_id, progress, req_id);
      DownloadStatus.setSkip(1);
    };
  }
  return payload;
}

const addRequestToList = (url, requestType, payload) => {
  const myData = {
    url: url,
    requestType: requestType,
    payload: payload
  };
  requestList.push(myData);
  return myData;
};

const removeRequestFromList = (myData) => {
  logger("in removeRequestFromList Before requestList.length=" + requestList.length, "RequestHandler");
  requestList = requestList.filter((request) => JSON.stringify(request) !== JSON.stringify(myData));
  logger("in removeRequestFromList After requestList.length=" + requestList.length, "RequestHandler");
};

const isRequestInList = (my_url, my_requestType, my_payload) => {
  let exists = false;
  for (let i = 0; i < requestList.length; i++) {
    logger(requestList[i].url + "<<==>>" + my_url, "RequestHandler");
    if (requestList[i].url === my_url) {
      logger("isRequestInList: URL Matched ", "RequestHandler");
    }
    if (requestList[i].requestType === my_requestType) {
      logger("isRequestInList: requestType Matched ", "RequestHandler");
    }
    if (requestList[i].payload === my_payload) {
      logger("isRequestInList: payload Matched ", "RequestHandler");
    } else {
      logger("isRequestInList: L" + requestList[i].payload, "RequestHandler");
      logger("isRequestInList: R" + my_payload, "RequestHandler");
    }
    if (
      requestList[i].url === my_url &&
      requestList[i].requestType === my_requestType &&
      JSON.stringify(requestList[i].payload) === JSON.stringify(my_payload)
    ) {
      exists = true;
      break;
    }
  }
  logger("isRequestInList: exists = " + exists, "RequestHandler")
  return exists;
};

function preparePayload(requestPurpose, responseType, payload) {
  payload["requestPurpose"] = requestPurpose;
  payload["responseType"] = responseType;
  payload["devname"] = "shopify-support-portal";
  // payload["cache"] = { maxAge: 3600 };
  logger(JSON.stringify(payload), 'RequestHandler:preparePayload');
  return payload;
}

function prepareURL(url, requestType, payload) {
  let new_url = url;
  if (requestType === "get") {
    new_url = url + "?";
    Object.keys(payload).forEach((key) => {
      new_url = new_url + key + "=" + payload[key] + "&";
      // logger("prepareURL:" + new_url + " - key=" + key, progName);
    });
  }
  logger("Final URL: " + new_url, progName);
  return new_url;
}

function isURLOK(req_url) {
  let url_ok = false;
  for (let i = 0; i < urlSuffixes.length; i++) {
    if (req_url.endsWith(urlSuffixes[i])) {
      url_ok = true;
      break;
    }
  }
  return url_ok
}

export const requestHandlerFunction = (url, requestPurpose, requestType, responseType, payload = {}) => {
  logger("Entering requestHandlerFunction: for " + requestPurpose +
    ", requestList.length=" + requestList.length, "=======> RequestHandler");
  logger("Entering requestHandlerFunction: url=" + url);
  if (!isURLOK(url)) {
    return new Promise(function (resolve, reject) {
      const myErr = { "response": { "status": 471, "data": { "MSG": "Requested URL Not supported" } } };
      logger("Requested URL Not supported: " + url, progName + ":requestHandlerFunction");
      reject(myErr)
    });
  }
  let myreqNo = reqSeqNo + 1;
  reqSeqNo += 1;
  let inp_payload = JSON.stringify(payload);
  let toContinue = checkRequest(url, inp_payload, requestPurpose, requestType, responseType);
  if (!toContinue) {
    return new Promise(function (resolve, reject) {
      const myErr = { "response": { "status": 470, "data": { "MSG": "Duplicate Request" } } };
      reject(myErr)
    });
  }

  let dataAdded = addRequestToList(url, requestType, inp_payload);
  let new_payload = preparePayload(requestPurpose, responseType, payload);
  url = prepareURL(url, requestType, new_payload);
  let final_payload = setProgressHandler(new_payload, myreqNo);

  const api = axios.create();


  if (requestType === "get") {
    logger("Request being Submitted for " + requestPurpose, "RequestHandler")
    return new Promise(function (resolve, reject) {
      api
        .get(url, final_payload)
        .then(function (response) {
          logger("SUCCESS in .get.then + myreqNo=" + myreqNo, "RequestHandler:requestHandlerFunction");
          resolve([response, myreqNo]);
        })
        .catch(function (error) {
          logger(("Error: Cannot execute " + requestPurpose), progName);
          logger(error, progName);
          reject();
        })
        .finally(function () {
          logger("Finally Block url=" + url, progName);
          logger("Finally Block, removing request from list myreqNo=" + myreqNo, progName);
          removeRequestFromList(dataAdded);
        });
    });
  } else if (requestType === "post") {
    return new Promise(function (resolve, reject) {
      axios
        .post(url, payload)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          logger(("Error: Cannot execute " + requestPurpose), 'RequestHandler');
          logger(error, 'RequestHandler');
          reject();
        }).finally(function () {
          removeRequestFromList(dataAdded);
        });
    });
  } else {
    logger("ERR: Request Type not supported", 'RequestHandler');
  }
};
