import React,{useState,useEffect,useRef} from 'react';
import './css/ShopifyPortal.css';
import { BASE_URL } from "../lib/common";
import { requestHandlerFunction } from "../lib/RequestHandler";
import DataExportComponent from './DataExportComponent';

const UxAnalytics = () => {
    const [reportStoreList, setReportStoreList] = useState(null);

    function fetchReportStoreList() {
        const url = BASE_URL + "sbv/support/getReportStoreList";
        fetch(url)
            .then(response => response.json())
            .then(data => setReportStoreList(data.data))
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
            fetchReportStoreList();
    }, [])
 
    return (
        <div>
            <DataExportComponent reportStoreList={reportStoreList} />
        </div>
    );
}
export default UxAnalytics;