import React, { useState } from 'react';
import './css/DataExportComponent.css';

const ExpandableSection = ({ title, reports }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="expandable-section" onClick={handleToggle}>
      <div className="section-title" >{title}</div>
      {expanded && (
        <div className="link-list">
          {reports.map((report, index) => (
            <div key={index} className="download-link">
              <a href={`http://64.227.152.133:7070/static/uxa/${title}/reports/${report}`} download>
                {report}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const DataExportComponent = ({ reportStoreList }) => {
  return (
    reportStoreList && <div className="data-export-container">
      {Object.keys(reportStoreList).map((key) => (
        <ExpandableSection key={key} title={key} reports={reportStoreList[key]} />
      ))}
    </div>

  );
};

export default DataExportComponent;
