import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Carousel, Container, Form, Button } from 'react-bootstrap';
import './css/ImgAnaAnnotateCard.css';
import Loader from './Loader';
import { BASE_URL } from "../lib/common";

const ImgAnaAnnotateCard = ({ store, ph, imgObj, handleButtonClick }) => {
    return (
        <div className='imgAnaAnnotateCard'>
            <Row className='m-0'>
                <Col className="mt-2 d-flex align-items-center" xs={6} md={3} data-bs-theme="dark">
                    <div className='imgWindow'>
                        {imgObj.name}
                        <div className="m-auto imgCont">
                            <img
                                className='imgBox'
                                src={`${BASE_URL}getProdImage?store_name=${store}&dress_id=${ph}&image_name=${imgObj.name}`}
                                alt="sbv"
                            />
                        </div>
                    </div>
                </Col>
                <Col className="mt-2" xs={6} md={9}>
                    <div className='secondWindow'>
                        <div className='title2'>AI SAYS (BLIP2)</div>
                        <div className='btnsDiv'>
                            <Button variant={(imgObj["is_front"]["ai_blip2vqa"] === "yes") ? "success" : (((imgObj["is_front"]["ai_blip2vqa"] === "no")) ? "danger" : "secondary")} style={{ cursor: "unset", outline: "unset" }} disabled>
                                {"is_front"}
                            </Button>
                        </div>
                        <div className='title2'>USER</div>
                        <div className='btnsDiv'>
                            <Button variant={(imgObj["is_front"]["user"] === "yes") ? "success" : (((imgObj["is_front"]["user"] === "no")) ? "danger" : "secondary")} style={{ cursor: "unset", outline: "unset" }} onClick={() => handleButtonClick(imgObj.name)}>
                                {"is_front"}
                            </Button>
                        </div>
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>target_pixel</td>
                                        <td>{imgObj["target_pixel"]}</td>
                                    </tr>
                                    <tr>
                                        <td>total_pixel</td>
                                        <td>{imgObj["total_pixel"]}</td>
                                    </tr>
                                    <tr>
                                        <td>left_margin</td>
                                        <td>{imgObj["left_margin"]}</td>
                                    </tr>
                                    <tr>
                                        <td>right_margin</td>
                                        <td>{imgObj["right_margin"]}</td>
                                    </tr>
                                    <tr>
                                        <td>top_margin</td>
                                        <td>{imgObj["top_margin"]}</td>
                                    </tr>
                                    <tr>
                                        <td>bottom_margin</td>
                                        <td>{imgObj["bottom_margin"]}</td>
                                    </tr>
                                    <tr>
                                        <td>percentage_pixel</td>
                                        <td>{imgObj["percentage_pixel"]}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ImgAnaAnnotateCard;  