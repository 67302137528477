import React, { useState, useEffect } from 'react';
import "./css/Annotate.css";
import { Link } from 'react-router-dom';
import Loader from './Loader';
import { BASE_URL } from "../lib/common";

const Annotate = () => {

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);

  const [toggleAlert, setToggleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertColor, setAlertColor] = useState('green');

  const alertStyle = {
    position: "absolute",
    right: "0",
    left: "0",
    zIndex: "10",
    backgroundColor: alertColor
  }

  function Alerts() {
    return (
      <>
        {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
      </>
    )
  }

  function showAlert(message, color, time = 3000) {
    setAlertMessage(message);
    setAlertColor(color);
    setToggleAlert(true);
    setTimeout(function () { setToggleAlert(false) }, time)
  }

  const fetchStoreList = () => {
    setLoading(true);
    const url = BASE_URL + `getAnnotateStoreList`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setStores(data);
        setLoading(false);
      })
      .catch((error) => {
        showAlert(`Error While Fetching store list data:${error}`, 'red');
        console.error('Error fetching store list data:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStoreList();
  }, [])


  return (
    <div className="annotateCont">
      <Alerts />
      {
        loading && <Loader />
      }
      {
        !loading && ((stores.length > 0) ? (
          <ul className='list'>
            {
              stores.map((store) => (
                <Link key={store} to={`./${store}`}><li>{store}</li></Link>
              ))
            }
          </ul>
        ) : (
          <div className='initDiv'>
            <div className="m-auto imgCont">
              <img
                className='imgBox'
                src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                alt="sbv"
              />
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Annotate;