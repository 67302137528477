import logger from "./logger";
import moment from 'moment';

let instance = null;

class DownloadStatus {
    static currDownloadStatus = null;
    constructor() {
        if (!instance) {
            instance = this;
            this.currDownloadStatus = { a: 0 };
            this.myDLArray = [];
            this.myTSArray = [];
            this.myKBPSArray = [];
            this.internetSpeed = 0;
            this.avgSpeed = 1;
            this.skip = 0;
            this.clientLog2Server = 0;
            this.key_reqid = -1; // Request ID for which speed will be assessed
        }
        return instance;
    }
    // This function is called to reduce the speed at which download status is updated. 
    setSkip(s) {
        if (this.skip > 40) {
            this.skip = 0;
        }
        else {
            this.skip = this.skip + s;
        }
    }

    setClientLog2Server(v) {
        this.clientLog2Server = v;
    }

    getClientLog2Server() {
        return this.clientLog2Server;
    }

    setStatus(artifactID, value, request_id) {
        if (value === 100) {
            logger("Debug--> this.key_reqid = " + this.key_reqid + ", request_id=" + request_id, "DownloadStatus");
        }
        if (value === 100 && this.key_reqid === request_id) {
            logger("assess speed stopped for this.key_reqid = " + this.key_reqid, "DownloadStatus");
            this.key_reqid = -1;
        }
        this.currDownloadStatus[artifactID] = value;
        logger("setStatus:-> " + JSON.stringify(this.currDownloadStatus), 'DownloadStatus');
    }
    getInternetSpeed() {
        return this.internetSpeed;
    }
    getStatus(artifactID) {
        if (!artifactID in this.currDownloadStatus) {
            this.setStatus(artifactID, 0);
        }
        return this.currDownloadStatus[artifactID];
    }
    assessSpeed() {
        if (this.myDLArray.length < 2) {
            logger("Not assessing - assessSpeed", "DownloadStatus");
            return
        }
        let i = this.myDLArray.length - 1;
        let ms = this.myTSArray[i].diff(this.myTSArray[i - 1]);
        let data = (this.myDLArray[i] - this.myDLArray[i - 1]) * 8;
        let kbps = (data / 1024) / (ms / 1000);
        this.myKBPSArray.push(kbps);
        // logger("Entering - assessSpeed: myKBPSArray.psuh = " + kbps + ", ms=" + ms + ", data=" + data, "DownloadStatus");

        let sumSpeed = 0;
        let ct = 0
        for (let i = 0; i < this.myKBPSArray.length; i++) {
            sumSpeed += this.myKBPSArray[i]
            ct += 1
        }
        let avgMySpeed = sumSpeed / ct;
        this.avgSpeed = avgMySpeed;
        // logger("Entering - assessSpeed: sumSpeed = " + sumSpeed + ", ct=" + ct + ", avgMySpeed=" + avgMySpeed, "DownloadStatus");
        if (avgMySpeed > 10240) {
            // High
            this.internetSpeed = 3;
        }
        if (avgMySpeed >= 2048 && avgMySpeed <= 10240) {
            //Medium
            this.internetSpeed = 2;
        }
        if (avgMySpeed < 2048) {
            //low
            this.internetSpeed = 1;
        }
        logger("assessSpeed: internetSpeed =" + this.internetSpeed +
            ", avgSpeed =" + this.avgSpeed.toFixed(2) +
            ", CurrSpeed=" + kbps.toFixed(2) +
            ", request_id =" + this.key_reqid,
            "DownloadStatus");
    }
    setDLArrays(reqid, artifactID, ts, bytes) {
        // logger("setDLArrays: ts=" + ts.format('DD-MMM-YYYY-h-mm-ss-SSS') + ", bytes=" + bytes, 'DownloadStatus');
        if (this.key_reqid > 0 && reqid !== this.key_reqid) {
            return;
        }
        if (this.key_reqid === -1 && this.currDownloadStatus[artifactID] !== 100) {
            logger("Start assessSpeed -> this.key_reqid = " + this.key_reqid + ", reqid=" + reqid, "DownloadStatus");
            this.key_reqid = reqid;
        }
        if (this.myDLArray.length > 99) {
            this.myDLArray.shift();
            this.myTSArray.shift();
        }
        this.myDLArray.push(bytes);
        this.myTSArray.push(ts);
        this.assessSpeed();
    }
}

export default new DownloadStatus();
