import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Carousel, Container, Form, Button, Table } from 'react-bootstrap';
import Loader from './Loader';
import './css/Chatbot.css';
import check from '../assets/icons/check.png'
import checkH from '../assets/icons/checkH.png'
import cross from '../assets/icons/cross.png'
import crossH from '../assets/icons/crossH.png'
import onboardScreen from '../assets/Onboarding.jpg'
import { BASE_URL } from "../lib/common";

const Chatbot = () => {
    const [loading, setLoading] = useState(false);
    const [imgObjArray, setImgObjArray] = useState([]);
    const [isMissed, setIsMissed] = useState(false);
    const [rCount, setRCount] = useState([]);
    const [propmt, setPrompt] = useState("");
    const [userInput, setUserInput] = useState('');
    const [userName, setUserName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [storeList, setStoreList] = useState(null);

    const fetchDresses = (userPrompt) => {
        setLoading(true);
        const url = `http://64.227.152.133:5000/sbv/ml/imageSearch?store_name=${storeName}&user_name=${userName}&userPrompt=${userPrompt}&devName=shiv`;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setImgObjArray(data.products);
                setLoading(false);
            })
            .catch((error) => {
                // showAlert(`Error While Fetching data:${error}`, 'red');
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };


    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };
    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (storeName.trim() === '') { return; }

        if (userInput.trim() === '') { return; }

        setPrompt(userInput);
        fetchDresses(userInput);
        // setUserInput('');
    };

    const updateImgStatus = (product_handle, isRelevant) => {
        console.log("Img Relevancy: ", product_handle, isRelevant);

        if (!rCount.includes(product_handle)) {
            setRCount(prevIds => [...prevIds, product_handle]);
        }

        setImgObjArray((prevImgObjArray) =>
            prevImgObjArray.map((imgObj) =>
                imgObj.product_handle === product_handle ? { ...imgObj, isRelevant: isRelevant } : imgObj
            )
        );
    }

    const missingStatus = (isMissedStatus) => {
        console.log("MissingStatus: ", isMissedStatus);
        setIsMissed(isMissedStatus);
    }

    const submitFeedback = () => {
        // TODO: get next session number
        let sessionNo = 1;

        let responseObj = {
            sessionNo,
            userName,
            propmt,
            isMissed,
            imgObjArray
        }
        console.log("Feedback", responseObj);
        setImgObjArray({});
        setIsMissed(false);
        setRCount({});
        setPrompt("");
    }

    const fetchStoreList = () => {
        const url = BASE_URL + `getAnnotateStoreList`;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setStoreList(data);
            })
            .catch((error) => {
                console.error('Error fetching store list data:', error);
            });
    };

    useEffect(() => {
        fetchStoreList();
    }, [])

    function TagSelector() {
        if (storeList !== null) {
            return (
                <>
                    <option value={""} disabled >Choose a Store</option>
                    {storeList.map((store, i) => { return <option key={i} value={store}>{store}</option> })}
                </>
            );
        }
    }

    function onStoreChange(event) {
        setStoreName(event.target.value);
        setImgObjArray([]);
    }

    return (
        <div className='mt-1 chatbotScreen'>
            <Row className='m-0'>
                <Col className="mt-2" xs={12} md={7} data-bs-theme="dark">
                    <div className='imgWindow'>
                        {loading && <Loader />}
                        {!loading &&
                            ((imgObjArray.length > 0) ? (
                                <>
                                    <Carousel className="carouselCont">
                                        {imgObjArray.map((imgObj, idx) => (
                                            <Carousel.Item key={idx} className="carouselItm">
                                                {imgObj.product_handle}
                                                <div className="m-auto imgCont">
                                                    <img src={(imgObj.isRelevant === true) ? checkH : check} style={{ position: "absolute", width: "50px", height: "50px", top: '2px', left: "2px", zIndex: "10" }} alt='relevant' onClick={() => updateImgStatus(imgObj.product_handle, true)} />
                                                    <img src={(imgObj.isRelevant === false) ? crossH : cross} style={{ position: "absolute", width: "50px", height: "50px", top: '2px', right: "2px", zIndex: "10" }} alt='unrelevant' onClick={() => updateImgStatus(imgObj.product_handle, false)} />
                                                    <img
                                                        className='imgBox'
                                                        // src={`http://64.227.152.133:7070/static/media/dress-tags/fablestreet/data/${imgObj.product_handle}/${imgObj.imgUrl}`}
                                                        src={`${BASE_URL}getProdImage?store_name=${storeName}&dress_id=${imgObj.product_handle}&image_name=${imgObj.imgUrl}`}
                                                        alt="sbv"
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <div className='feedbackBtnCont'>
                                        <Form>
                                            <div className="m-2 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <Form.Label className='mr-3' style={{ margin: "unset" }} >Did I Missed Something ?</Form.Label>
                                                    <Form.Check
                                                        inline
                                                        label="YES"
                                                        name="isMissed"
                                                        type="radio"
                                                        id={`inline-radio-1`}
                                                        onClick={() => missingStatus(true)}
                                                        defaultChecked={(isMissed) ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="NO"
                                                        name="isMissed"
                                                        type="radio"
                                                        id={`inline-radio-2`}
                                                        onClick={() => missingStatus(false)}
                                                        defaultChecked={(!isMissed) ? true : false}
                                                    />
                                                </div>
                                                <Button size="sm" variant="dark" onClick={submitFeedback} disabled={(imgObjArray.length === rCount.length) ? false : true}>
                                                    Submit Feedback
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='promptCont'>
                                        <div style={{ background: "#525252" }}>
                                            Your Prompt : {propmt}
                                        </div>
                                        {/* <div style={{ fontSize: "15px" }}>
                                            {propmt}
                                        </div> */}
                                    </div>
                                </>
                            )
                                :
                                (
                                    <div className='initDiv'>
                                        <div className="m-auto imgCont">
                                            <img
                                                className='imgBox'
                                                src={onboardScreen}
                                                alt="sbv"
                                            />
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </Col>
                <Col className="mt-2" xs={12} md={5}>
                    <div className="chatWindow">
                        <div className="chat-container">
                            <div className="chat-box">
                                {!loading &&
                                    ((imgObjArray.length > 0) ? (
                                        <>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>ImageId</th>
                                                        <th>Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {imgObjArray.map((imgObj, idx) => (
                                                        <tr>
                                                            <td>
                                                                {idx+1}
                                                            </td>
                                                            <td>
                                                                {imgObj.product_handle}
                                                            </td>
                                                            <td>
                                                                {imgObj.score}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    ) :
                                        (
                                            <div className='initDiv'>
                                                <div className="m-auto imgCont">
                                                    <img
                                                        className='imgBox'
                                                        src="https://img.freepik.com/free-vector/features-overview-concept-illustration_114360-1500.jpg?size=626&ext=jpg&ga=GA1.1.1921206711.1694439131&semt=sph"
                                                        alt="sbv"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                            <div className="input-area">
                                <div className='selector'>
                                    <Form.Select size="sm" value={storeName} onChange={onStoreChange} name="storeName" id="storeName">
                                        <TagSelector />
                                    </Form.Select>
                                </div>
                                <Form onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Form.Group style={{ width: "100%", marginRight: "8px" }}>
                                        <Form.Control
                                            as="input"
                                            type="text"
                                            placeholder="Username"
                                            value={userName}
                                            onChange={handleUserNameChange}
                                            className='m-1 mb-2'
                                            required
                                        />
                                        <Form.Control
                                            as="input"
                                            type="text"
                                            placeholder="Type a creative prompt..."
                                            value={userInput}
                                            onChange={handleInputChange}
                                            autoFocus
                                            className='m-1'
                                        />
                                    </Form.Group>
                                    <Button type="submit" variant="secondary">
                                        Search
                                    </Button>
                                </Form>
                            </div>
                            {(userName === 'admin') && <div className="histBtnCont mt-3">
                                <Button className="mr-2" variant="secondary">
                                    Prev
                                </Button>
                                <Button className="ml-2" variant="secondary">
                                    Next
                                </Button>
                            </div>}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Chatbot;