import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Carousel, Container, Form, Button } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Loader from './Loader';
import './css/Chatbot.css';
import "./css/AnnotateCard.css";
import { BASE_URL } from "../lib/common";

const AnnotateCard = () => {
  const { store } = useParams();

  const [loading, setLoading] = useState(false);

  const [tagObjList, setTagObjList] = useState(null);
  const [parentTag, setParentTag] = useState("");
  const [childTag, setChildTag] = useState("");
  const [tgtField, setTgtField] = useState("");
  const [tgtValue, setTgtValue] = useState("");

  const [dressHandle, setDressHandle] = useState("");

  const [dressId, setDressId] = useState("");
  const [dressImgs, setDressImgs] = useState(null);
  const [curTag, setCurTag] = useState("");
  const [tagList, setTagList] = useState(null);
  const [dressDescription, setDressDescription] = useState(null);
  const [tagsDescription, setTagsDescription] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currDressNo, setCurrDressNo] = useState(1);
  const [totalDresses, setTotalDresses] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [pandingChangeCount, setPandingChangeCount] = useState(0);

  const [toggleAlert, setToggleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertColor, setAlertColor] = useState('green');

  const alertStyle = {
    position: "absolute",
    right: "0",
    left: "0",
    zIndex: "10",
    backgroundColor: alertColor
  }

  function Alerts() {
    return (
      <>
        {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
      </>
    )
  }

  function showAlert(message, color, time = 3000) {
    setAlertMessage(message);
    setAlertColor(color);
    setToggleAlert(true);
    setTimeout(function () { setToggleAlert(false) }, time)
  }

  const fetchTagObjListInfo = () => {
    const url = BASE_URL + `getTagObjList`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.tag_obj_list) {
          setTagObjList(data.tag_obj_list);
          console.log(data.tag_obj_list);
        }
      })
      .catch((error) => {
        showAlert(`Error While Fetching TagObjList data:${error}`, 'red');
        console.error('Error fetching TagObjList data:', error);
      });
  };

  const fetchDressInfo = (dressNo, ph, sf) => {
    // TODO USE store
    setLoading(true);
    // const url = BASE_URL + `getNextDressDescription?store=${store}&dressNo=${dressNo}`;
    ph = encodeURIComponent(ph);
    const url = BASE_URL + `getNextDressDesc?store=${store}&product_handle=${ph}&parent_tag=${parentTag}&child_tag=${childTag}&tgt_field=${tgtField}&tgt_value=${tgtValue}&start_from=${sf}&prod_count=${(ph !== "") ? 0 : 101}`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.dressInfo) {
          setDressId(data.dressInfo.dressId);
          setDressImgs(data.dressInfo.dressImgs);
          setTagList(data.dressInfo.tagList);
          setDressDescription(data.dressInfo.dressDescription);
          if (!data.dressInfo.tagList.includes(parentTag)) setCurTag("");
          else if (curTag === "") setCurTag(parentTag);
          // setTotalDresses(data.totalDresses);
          if (ph == "" || ph == "admin" || ph == "nontaggedproducts" || ph.endsWith(".json")) {
            // setFilteredProducts(data.filteredProducts);
            // setTotalDresses(data.filteredProducts.length);
            setFilteredProducts((prev) => {
              const updatedArray = prev.length > 0 ? prev.slice(0, -1) : [];
              return [...updatedArray, ...data.filteredProducts];
            });

            if (data.filteredProducts.length == 101) setTotalDresses((prev) => prev + data.filteredProducts.length - 1);
            else setTotalDresses((prev) => prev + data.filteredProducts.length);

            setHasNext((dressNo < sf + data.filteredProducts.length) ? true : false);
            setHasPrev((dressNo >= 1) ? true : false);
            console.log(dressNo, sf + data.filteredProducts.length);
          }
          setPandingChangeCount(0);
        }
        setLoading(false);
      })
      .catch((error) => {
        showAlert(`Error While Fetching data:${error}`, 'red');
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const fetchTagsDescription = () => {
    const url = BASE_URL + `getTagDescription?store=${store}&dressId=${dressId}&tagsList=${JSON.stringify(tagList)}`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // setTagsDescription(data.tagDescription);
        setTagsDescription(data.tagDescription);
      })
      .catch((error) => {
        showAlert(`Error While Fetching data:${error}`, 'red');
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    setCurrDressNo(1);
    fetchTagObjListInfo();
    // fetchDressInfo(0, "");
  }, [])

  useEffect(() => {
    if (dressId !== "") {
      fetchTagsDescription();
    }
  }, [dressId])

  const handleCurrDressNoChange = (e) => {
    const newDressNo = parseInt(e.target.value, 10);
    setCurrDressNo(newDressNo);
  };

  const handleCurrDressNo = (e) => {
    let newDressNo = parseInt(e.target.value, 10);
    newDressNo = (isNaN(newDressNo) || newDressNo < 1 || newDressNo > totalDresses) ? 1 : newDressNo;
    setCurrDressNo(newDressNo);
    fetchDressInfo(newDressNo - 1, filteredProducts[newDressNo - 1], 0);
  };

  const saveFeedback = () => {
    const url = BASE_URL + `setTagDescription`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ store, dressId, tag: curTag, tagDescription: tagsDescription[curTag] }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showAlert(`FEEDBACK SAVED SUCCESSFULLY.`, 'lightgreen');
          console.log("FEEDBACK SAVED SUCCESSFULLY.");
          setPandingChangeCount(0);
        } else {
          showAlert(`Error While Saving Feedback: ${data}`, 'red');
          console.log("ERROR WHILE SAVING FEEDBACK : ", data);
        }
      })
      .catch((error) => {
        showAlert(`Error setting dress description: ${error}`, 'red');
        console.log('Error setting dress description:', error);
      });
  };

  const updateTagsDiscription = (purpose, key) => {
    const updatedTagsDiscription = { ...tagsDescription };
    updatedTagsDiscription[curTag][curTag][key].user = updatedTagsDiscription[curTag][curTag][key].user === 'yes' ? 'tbd' : 'yes';
    setTagsDescription(updatedTagsDiscription);
    setPandingChangeCount(pandingChangeCount + 1);
  }

  const resetFeedback = () => {
    if (pandingChangeCount > 0) {
      if (window.confirm('Do you want to reset current feedback?')) {
        fetchTagsDescription();
        setPandingChangeCount(0);
        showAlert(`FEEDBACK HAS BEEN RESET SUCCESSFULLY.`, 'lightgreen');
        console.log("FEEDBACK HAS BEEN RESET SUCCESSFULLY.");
      }
    }
  }

  const fetchNextDress = () => {
    if (pandingChangeCount > 0) {
      //   if (window.confirm('Do you want to save current feedback?')) {
      saveFeedback();
      //   }
    }

    // if (dressHandle.trim().toLowerCase() == "admin") {
    if (dressHandle.trim().toLowerCase() == "admin" || dressHandle.trim().toLowerCase() == "nontaggedproducts" || dressHandle.trim().endsWith(".json")) {
      fetchDressInfo(currDressNo, filteredProducts[currDressNo], 0);
    }
    else {
      if (currDressNo % 100 === 0 && totalDresses <= currDressNo) {
        fetchDressInfo(currDressNo, "", currDressNo + 1); // +1-1 = 0
      }
      else {
        fetchDressInfo(currDressNo, filteredProducts[currDressNo], 0); // +1-1 = 0
      }
    }

    setCurrDressNo(currDressNo + 1);
    setHasNext((currDressNo + 1 < filteredProducts.length) ? true : false);
    setHasPrev((currDressNo + 1 > 1) ? true : false);
    // setCurTag("");
  }

  const fetchPrevDress = () => {
    if (pandingChangeCount > 0) {
      //   if (window.confirm('Do you want to save current feedback?')) {
      saveFeedback();
      //   }
    }
    fetchDressInfo(currDressNo - 2, filteredProducts[currDressNo - 2], 0); // -1-1 = -2
    setCurrDressNo(currDressNo - 1);
    setHasNext((currDressNo - 1 < filteredProducts.length) ? true : false);
    setHasPrev((currDressNo - 1 > 1) ? true : false);
    // setCurTag("");
  }

  function TagSelector() {
    if (tagList !== null) {
      return (
        <>
          <option value={""} disabled >Choose a tag</option>
          {tagList.map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function ParentTagSelector() {
    if (tagObjList !== null) {
      return (
        <>
          <option value={""} disabled >Choose a Parent tag</option>
          {Object.keys(tagObjList).map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function ChildTagSelector() {
    if (tagObjList !== null && tagObjList[parentTag]) {
      return (
        <>
          <option value={""} disabled >Choose a Child tag</option>
          {Object.keys(tagObjList[parentTag]).map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function TgtTagSelector() {
    if (tagObjList !== null && tagObjList[parentTag] && tagObjList[parentTag][childTag]) {
      return (
        <>
          <option value={""} disabled >Choose a Tgt Filed</option>
          {Object.keys(tagObjList[parentTag][childTag]).map((tag, i) => { if (tag !== 'prompt' && tag !== 'other-tags') return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function TgtValueSelector() {
    if (tagObjList !== null && tagObjList[parentTag] && tagObjList[parentTag][childTag] && tagObjList[parentTag][childTag][tgtField]) {
      return (
        <>
          <option value={""} disabled >Choose a Tgt Value</option>
          {["yes", "no", "tbd"].map((val, i) => { return <option key={i} value={val}>{val}</option> })}
        </>
      );
    }
  }

  function onTagChange(event) {
    if (pandingChangeCount > 0) {
      //   if (window.confirm('Do you want to save current feedback?')) {
      saveFeedback();
      //   }
    }
    // console.log(event.target.value);
    setCurTag(event.target.value);
  }

  const handleParentTagChange = (e) => {
    setParentTag(e.target.value);
    // setCurTag(e.target.value);
  };

  const handleChildTagChange = (e) => {
    setChildTag(e.target.value);
  };

  const handleTgtFieldChange = (e) => {
    setTgtField(e.target.value);
  };

  const handleTgtValueChange = (e) => {
    setTgtValue(e.target.value);
  };

  const handleDressHandleChange = (e) => {
    setDressHandle(e.target.value);
  };

  const resetAll = () => {
    setDressId("");
    setDressImgs(null);
    // setCurTag("");
    setTagList(null);
    setDressDescription(null);
    setTagsDescription(null);
    setFilteredProducts([]);
    setCurrDressNo(1);
    setTotalDresses(0);
    setHasNext(false);
    setHasPrev(false);
    setPandingChangeCount(0);
  }

  const filterProducts = (e) => {
    e.preventDefault();
    if (dressHandle.trim().toLowerCase() === "admin" || dressHandle.trim().toLowerCase() == "nontaggedproducts" || dressHandle.trim().endsWith(".json")) {
      resetAll();
      if (dressHandle.trim().toLowerCase() === "admin") fetchDressInfo(0, "admin", 0);
      else if (dressHandle.trim().toLowerCase() === "nontaggedproducts") fetchDressInfo(0, "nontaggedproducts", 0);
      else fetchDressInfo(0, dressHandle.trim(), 0);
    }
    else if (dressHandle) {
      resetAll();
      fetchDressInfo(0, dressHandle, 0);
      setHasNext(true);
    }
    else {
      if (parentTag.trim() === "") {
        showAlert(`Please Choose Parent Tag.`, 'red');
        return;
      }
      if (childTag.trim() === "") {
        showAlert(`Please Choose Child Tag.`, 'red');
        return;
      }
      if (tgtField.trim() === "") {
        showAlert(`Please Choose Tgt Filed.`, 'red');
        return;
      }
      if (tgtValue.trim() === "") {
        showAlert(`Please Choose Tgt Value.`, 'red');
        return;
      }
      resetAll();
      fetchDressInfo(0, "", 0);
    }
  }

  return (
    <div className="annotateCard">
      <Alerts />
      {/* <div className="title">Annotation for {store}</div> */}
      <div className="title">
        <Form onSubmit={filterProducts} style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Select className='m-1' size="sm" value={parentTag} onChange={handleParentTagChange} name="parentTag" id="parentTag">
            <ParentTagSelector />
          </Form.Select>
          <Form.Select className='m-1' size="sm" value={childTag} onChange={handleChildTagChange} name="ChildTag" id="childTag">
            <ChildTagSelector />
          </Form.Select>
          <Form.Select className='m-1' size="sm" value={tgtField} onChange={handleTgtFieldChange} name="tgtField" id="tgtField">
            <TgtTagSelector />
          </Form.Select>
          <Form.Select className='m-1' size="sm" value={tgtValue} onChange={handleTgtValueChange} name="tgtValue" id="tgtValue">
            <TgtValueSelector />
          </Form.Select>
          <Form.Control
            as="input"
            type="text"
            placeholder="Enter Dress Handle"
            value={dressHandle}
            name='dressHandle'
            onChange={handleDressHandleChange}
            className='m-1'
          />
          <Button type="submit" variant="secondary">
            Filter
          </Button>
        </Form>
      </div>
      <Row className='m-0'>
        <Col className="mt-2" xs={12} md={4} data-bs-theme="dark">
          <div className='imgWindow'>
            {dressId}
            {(tagsDescription && tagsDescription["misc"] && tagsDescription["misc"]["vendor"]) && <div>{`${tagsDescription["misc"]["vendor"]}`}</div>}
            {loading && <Loader />}
            {!loading &&
              ((dressImgs != null && dressImgs.length !== 0) ? (
                <Carousel className="carouselCont">
                  {dressImgs.map((imgFile, idx) => (
                    <Carousel.Item key={idx} className="carouselItm">
                      {/* {dressId} */}
                      <div className="m-auto imgCont">
                        <img
                          className='imgBox'
                          // src={`http://64.227.152.133:7070/static/media/dress-tags/${store}/data/${dressId}/${imgFile}`}
                          src={`${BASE_URL}getProdImage?store_name=${store}&dress_id=${dressId}&image_name=${imgFile}`}
                          alt="sbv"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )
                :
                (
                  <div className='initDiv'>
                    <div className="m-auto imgCont">
                      <img
                        className='imgBox'
                        src="https://img.freepik.com/free-vector/file-searching-concept-illustration_114360-4125.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                        alt="sbv"
                      />
                    </div>
                  </div>
                ))
            }
          </div>
        </Col>
        <Col className="mt-2" xs={12} md={8}>
          <div className='secondWindow'>
            {loading && <Loader />}
            {(!loading && dressId) ?
              (
                <>
                  <div className='selector'>
                    {/* <label htmlFor="tag">TagName</label> */}
                    <Form.Select size="sm" value={curTag} onChange={onTagChange} name="tag" id="tag">
                      <TagSelector />
                    </Form.Select>
                  </div>

                  {
                    (tagsDescription && curTag !== "" && tagsDescription[curTag] && tagsDescription[curTag][curTag]) && (
                      <>
                        <div className='title2'>AI SAYS (BLIP2)</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["ai_blip2vqa"] === "yes") ? "success" : (((tagsDescription[curTag][curTag][key]["ai_blip2vqa"] === "no")) ? "danger" : "secondary")} style={{ cursor: "unset", outline: "unset" }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div>
                        <div className='title2'>AI SAYS (GEMINI)</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["ai_gemini2vqa"] === "yes") ? "success" : (((tagsDescription[curTag][curTag][key]["ai_gemini2vqa"] === "no")) ? "danger" : "secondary")} style={{ cursor: "unset", outline: "unset" }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div>
                        <div className='title2'>METADATA SAYS</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["metadata"] === "yes") ? "success" : "secondary"} style={{ cursor: "unset", outline: "unset" }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div>
                        <div className='title2'>USER SAYS</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["user"] === "yes") ? "success" : (((tagsDescription[curTag][curTag][key]["user"] === "no")) ? "danger" : "secondary")} onClick={() => { updateTagsDiscription(curTag, key) }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div>
                      </>
                    )
                  }

                  <div className="histBtnCont mt-3">
                    <Link to={`./${dressId}`} target="_blank">
                      <Button className="ml-2 mr-2" variant="dark">
                        Image Analysis
                      </Button>
                    </Link>

                    <div className='mr-2 pageNoCont'>
                      <input className='pageIn' type="number" value={currDressNo} onBlur={handleCurrDressNo} onChange={handleCurrDressNoChange}
                      /> / {totalDresses}
                    </div>
                    {/* <div className='pageNoCont'> {currDressNo + 1} / {totalDresses}</div> */}
                    <Button className="ml-2 mr-2" variant="dark" onClick={fetchPrevDress} disabled={!hasPrev}>
                      Prev
                    </Button>
                    {/* {
                      tagsDescription && curTag && <Button className="ml-2" variant="primary" disabled={(pandingChangeCount > 0) ? false : true} onClick={saveFeedback}>
                        Save Feedback
                      </Button>
                    } */}
                    {
                      tagsDescription && curTag && <Button className="ml-2" variant="primary" disabled={(pandingChangeCount > 0) ? false : true} onClick={resetFeedback}>
                        Reset Feedback
                      </Button>
                    }
                    <Button className="ml-2" variant="dark" onClick={fetchNextDress} disabled={!hasNext}>
                      Next
                    </Button>
                  </div>
                  {
                    dressDescription &&
                    <div className='dressDes'>
                      {dressDescription}
                    </div>
                  }
                </>
              )
              :
              (
                <div className='initDiv'>
                  <div className="m-auto imgCont">
                    <img
                      className='imgBox'
                      src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                      alt="sbv"
                    />
                  </div>
                </div>
              )
            }
            {

            }
            {/* {!loading &&
              ((tagsDescription != null && curTag) ? (
                <>
                  <div className='title2'>AI SAYS</div>
                  <div className='btnsDiv'>
                    {
                      Object.keys(tagsDescription[curTag]).map((key) => (
                        <Button key={key} variant={(tagsDescription[curTag][key]["ai_blip2vqa"] === "yes") ? "success" : "secondary"} style={{ cursor: "unset", outline: "unset" }}>
                          {key}
                        </Button>
                      ))
                    }
                  </div>
                  <div className='title2'>USER SAYS</div>
                  <div className='btnsDiv'>
                    {
                      Object.keys(tagsDescription[curTag]).map((key) => (
                        <Button key={key} variant={(tagsDescription[curTag][key]["user"] === "yes") ? "success" : "secondary"} onClick={() => { updateTagsDiscription(curTag, key) }}>
                          {key}
                        </Button>
                      ))
                    }
                  </div>
                </>
              )
                :
                (
                  <div className='initDiv'>
                    <div className="m-auto imgCont">
                      <img
                        className='imgBox'
                        src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                        alt="sbv"
                      />
                    </div>
                  </div>
                ))
            } */}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AnnotateCard;