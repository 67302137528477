import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Carousel, Container, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import './css/Chatbot.css';
import "./css/AnnotateCard.css";
import { BASE_URL } from "../lib/common";

const AnnotateCard = () => {
  const { store } = useParams();

  const [loading, setLoading] = useState(false);

  const [tagObjList, setTagObjList] = useState(null);
  const [parentTag, setParentTag] = useState("");
  const [childTag, setChildTag] = useState("");
  const [tgtField, setTgtField] = useState("");

  const [dressHandle, setDressHandle] = useState("");

  const [dressId, setDressId] = useState("");
  const [dressImgs, setDressImgs] = useState(null);
  const [tagList, setTagList] = useState(null);
  const [dressDescription, setDressDescription] = useState(null);
  const [colorDescription, setColorDescription] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currDressNo, setCurrDressNo] = useState(1);
  const [totalDresses, setTotalDresses] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [pandingChangeCount, setPandingChangeCount] = useState(0);

  const [toggleAlert, setToggleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertColor, setAlertColor] = useState('green');

  const alertStyle = {
    position: "absolute",
    right: "0",
    left: "0",
    zIndex: "10",
    backgroundColor: alertColor
  }

  function Alerts() {
    return (
      <>
        {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
      </>
    )
  }

  function showAlert(message, color, time = 3000) {
    setAlertMessage(message);
    setAlertColor(color);
    setToggleAlert(true);
    setTimeout(function () { setToggleAlert(false) }, time)
  }

  const fetchTagObjListInfo = () => {
    const url = BASE_URL + `getTagObjList`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.tag_obj_list) {
          setTagObjList(data.tag_obj_list);
          console.log(data.tag_obj_list);
        }
      })
      .catch((error) => {
        showAlert(`Error While Fetching TagObjList data:${error}`, 'red');
        console.error('Error fetching TagObjList data:', error);
      });
  };

  const fetchDressInfo = (dressNo, ph, sf) => {
    setLoading(true);
    // const url = BASE_URL + `getNextDressDescription?store=${store}&dressNo=${dressNo}`;
    ph = encodeURIComponent(ph);
    const url = BASE_URL + `getNextDressDesc?store=${store}&product_handle=${ph}&parent_tag=${parentTag}&child_tag=${childTag}&tgt_field=${tgtField}&start_from=${sf}&prod_count=${(ph !== "") ? 0 : 26}`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.dressInfo) {
          setDressId(data.dressInfo.dressId);
          setDressImgs(data.dressInfo.dressImgs);
          setTagList(data.dressInfo.tagList);
          setDressDescription(data.dressInfo.dressDescription);
          // setTotalDresses(data.totalDresses);
          if (ph == "") {
            // setFilteredProducts(data.filteredProducts);
            // setTotalDresses(data.filteredProducts.length);
            setFilteredProducts((prev) => {
              const updatedArray = prev.length > 0 ? prev.slice(0, -1) : [];
              return [...updatedArray, ...data.filteredProducts];
            });

            if (data.filteredProducts.length == 26) setTotalDresses((prev) => prev + data.filteredProducts.length - 1);
            else setTotalDresses((prev) => prev + data.filteredProducts.length);

            setHasNext((dressNo < sf + data.filteredProducts.length) ? true : false);
            setHasPrev((dressNo >= 1) ? true : false);
            console.log(dressNo, sf + data.filteredProducts.length);
          }
          setPandingChangeCount(0);
        }
        setLoading(false);
      })
      .catch((error) => {
        showAlert(`Error While Fetching data:${error}`, 'red');
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const fetchColorDescription = () => {
    const url = BASE_URL + `getColorDescription?store=${store}&dressId=${dressId}`;

    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setColorDescription(data.colorDescription);
      })
      .catch((error) => {
        showAlert(`Error While Fetching data:${error}`, 'red');
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    setCurrDressNo(1);
    fetchTagObjListInfo();
    // fetchDressInfo(0, "");
  }, [])

  useEffect(() => {
    if (dressId !== "") {
      fetchColorDescription();
    }
  }, [dressId])

  const handleCurrDressNoChange = (e) => {
    const newDressNo = parseInt(e.target.value, 10);
    setCurrDressNo(newDressNo);
  };

  const handleCurrDressNo = (e) => {
    let newDressNo = parseInt(e.target.value, 10);
    newDressNo = (isNaN(newDressNo) || newDressNo < 1 || newDressNo > totalDresses) ? 1 : newDressNo;
    setCurrDressNo(newDressNo);
    fetchDressInfo(newDressNo - 1, filteredProducts[newDressNo - 1], 0);
  };

  // const saveFeedback = () => {
  //   const url = BASE_URL + `setTagDescription`;
  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ store, dressId, tag: curTag, tagDescription: tagsDescription[curTag] }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         showAlert(`FEEDBACK SAVED SUCCESSFULLY.`, 'lightgreen');
  //         console.log("FEEDBACK SAVED SUCCESSFULLY.");
  //         setPandingChangeCount(0);
  //       } else {
  //         showAlert(`Error While Saving Feedback: ${data}`, 'red');
  //         console.log("ERROR WHILE SAVING FEEDBACK : ", data);
  //       }
  //     })
  //     .catch((error) => {
  //       showAlert(`Error setting dress description: ${error}`, 'red');
  //       console.log('Error setting dress description:', error);
  //     });
  // };

  // const updateTagsDiscription = (purpose, key) => {
  //   const updatedTagsDiscription = { ...tagsDescription };
  //   updatedTagsDiscription[curTag][curTag][key].user = updatedTagsDiscription[curTag][curTag][key].user === 'yes' ? 'no' : 'yes';
  //   setTagsDescription(updatedTagsDiscription);
  //   setPandingChangeCount(pandingChangeCount + 1);
  // }

  // const resetFeedback = () => {
  //   if (pandingChangeCount > 0) {
  //     if (window.confirm('Do you want to reset current feedback?')) {
  //       fetchColorDescription();
  //       setPandingChangeCount(0);
  //       showAlert(`FEEDBACK HAS BEEN RESET SUCCESSFULLY.`, 'lightgreen');
  //       console.log("FEEDBACK HAS BEEN RESET SUCCESSFULLY.");
  //     }
  //   }
  // }

  const fetchNextDress = () => {
    if (pandingChangeCount > 0) {
      //   if (window.confirm('Do you want to save current feedback?')) {
      // saveFeedback();
      //   }
    }
    // if(currDressNo % 25 === 0 && totalDresses <= currDressNo)
    if (currDressNo % 25 === 0 && totalDresses <= currDressNo) {
      fetchDressInfo(currDressNo, "", currDressNo + 1); // +1-1 = 0
    }
    else {
      fetchDressInfo(currDressNo, filteredProducts[currDressNo], 0); // +1-1 = 0
    }

    setCurrDressNo(currDressNo + 1);
    setHasNext((currDressNo + 1 < filteredProducts.length) ? true : false);
    setHasPrev((currDressNo + 1 > 1) ? true : false);
    // setCurTag("");
  }

  const fetchPrevDress = () => {
    if (pandingChangeCount > 0) {
      //   if (window.confirm('Do you want to save current feedback?')) {
      // saveFeedback();
      //   }
    }
    fetchDressInfo(currDressNo - 2, filteredProducts[currDressNo - 2], 0); // -1-1 = -2
    setCurrDressNo(currDressNo - 1);
    setHasNext((currDressNo - 1 < filteredProducts.length) ? true : false);
    setHasPrev((currDressNo - 1 > 1) ? true : false);
    // setCurTag("");
  }

  function TagSelector() {
    if (tagList !== null) {
      return (
        <>
          <option value={""} disabled >Choose a tag</option>
          {tagList.map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function ParentTagSelector() {
    if (tagObjList !== null) {
      return (
        <>
          <option value={""} disabled >Choose a Parent tag</option>
          {Object.keys(tagObjList).map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function ChildTagSelector() {
    if (tagObjList !== null && tagObjList[parentTag]) {
      return (
        <>
          <option value={""} disabled >Choose a Child tag</option>
          {Object.keys(tagObjList[parentTag]).map((tag, i) => { return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  function TgtTagSelector() {
    if (tagObjList !== null && tagObjList[parentTag] && tagObjList[parentTag][childTag]) {
      return (
        <>
          <option value={""} disabled >Choose a Tgt Filed</option>
          {Object.keys(tagObjList[parentTag][childTag]).map((tag, i) => { if (tag !== 'prompt' && tag !== 'other-tags') return <option key={i} value={tag}>{tag}</option> })}
        </>
      );
    }
  }

  const handleParentTagChange = (e) => {
    setParentTag(e.target.value);
    // setCurTag(e.target.value);
  };

  const handleChildTagChange = (e) => {
    setChildTag(e.target.value);
  };

  const handleTgtFieldChange = (e) => {
    setTgtField(e.target.value);
  };

  const handleDressHandleChange = (e) => {
    setDressHandle(e.target.value);
  };

  const resetAll = () => {
    setDressId("");
    setDressImgs(null);
    // setCurTag("");
    setTagList(null);
    setDressDescription(null);
    setColorDescription(null);
    setFilteredProducts([]);
    setCurrDressNo(1);
    setTotalDresses(0);
    setHasNext(false);
    setHasPrev(false);
    setPandingChangeCount(0);
  }

  const filterProducts = (e) => {
    e.preventDefault();
    if (dressHandle) {
      resetAll();
      fetchDressInfo(0, dressHandle, 0);
      setHasNext(true);
    }
    else {
      if (parentTag.trim() === "") {
        showAlert(`Please Choose Parent Tag.`, 'red');
        return;
      }
      if (childTag.trim() === "") {
        showAlert(`Please Choose Child Tag.`, 'red');
        return;
      }
      if (tgtField.trim() === "") {
        showAlert(`Please Choose Tgt Filed.`, 'red');
        return;
      }
      resetAll();
      fetchDressInfo(0, "", 0);
    }
  }

  const ColorCircle = ({ rgb_code, pixel_count, brightness }) => {
    const colorStyle = {
      backgroundColor: `rgb(${rgb_code[0]}, ${rgb_code[1]}, ${rgb_code[2]})`,
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      margin: '10px',
    };

    return (
      <div style={{display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <div style={colorStyle}></div>
        <p style={{fontSize:"14px", textAlign:"center"}}>RGB: {rgb_code.join(', ')}</p>
        <p style={{fontSize:"14px", textAlign:"center"}}>Pixel Count: {pixel_count}</p>
        <p style={{fontSize:"14px", textAlign:"center"}}>Brightness: {brightness}</p>
      </div>
    );
  };

  const ColorCircleList = ({ colors }) => {
    return (
      <div style={{display: "flex", flexWrap:"wrap", justifyContent:"space-between", margin:'10px'}}>
        {colors.map((color, index) => (
          <ColorCircle key={index} {...color} />
        ))}
      </div>
    );
  };

  return (
    <div className="annotateCard">
      <Alerts />
      {/* <div className="title">Annotation for {store}</div> */}
      <div className="title">
        <Form onSubmit={filterProducts} style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Select className='m-1' size="sm" value={parentTag} onChange={handleParentTagChange} name="parentTag" id="parentTag">
            <ParentTagSelector />
          </Form.Select>
          <Form.Select className='m-1' size="sm" value={childTag} onChange={handleChildTagChange} name="ChildTag" id="childTag">
            <ChildTagSelector />
          </Form.Select>
          <Form.Select className='m-1' size="sm" value={tgtField} onChange={handleTgtFieldChange} name="tgtField" id="tgtField">
            <TgtTagSelector />
          </Form.Select>
          <Form.Control
            as="input"
            type="text"
            placeholder="Enter Dress Handle"
            value={dressHandle}
            name='dressHandle'
            onChange={handleDressHandleChange}
            className='m-1'
          />
          <Button type="submit" variant="secondary">
            Filter
          </Button>
        </Form>
      </div>
      <Row className='m-0'>
        <Col className="mt-2" xs={12} md={4} data-bs-theme="dark">
          <div className='imgWindow'>
            {dressId}
            {loading && <Loader />}
            {!loading &&
              ((dressImgs != null && dressImgs.length !== 0) ? (
                <Carousel className="carouselCont">
                  {dressImgs.map((imgFile, idx) => (
                    <Carousel.Item key={idx} className="carouselItm">
                      {/* {dressId} */}
                      <div className="m-auto imgCont">
                        <img
                          className='imgBox'
                          // src={`http://64.227.152.133:7070/static/media/dress-tags/${store}/data/${dressId}/${imgFile}`}
                          src={`${BASE_URL}getProdImage?store_name=${store}&dress_id=${dressId}&image_name=${imgFile}`}
                          alt="sbv"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )
                :
                (
                  <div className='initDiv'>
                    <div className="m-auto imgCont">
                      <img
                        className='imgBox'
                        src="https://img.freepik.com/free-vector/file-searching-concept-illustration_114360-4125.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                        alt="sbv"
                      />
                    </div>
                  </div>
                ))
            }
          </div>
        </Col>
        <Col className="mt-2" xs={12} md={8}>
          <div className='secondWindow'>
            {loading && <Loader />}
            {(!loading && dressId) ?
              (
                <>
                  {
                    (colorDescription && colorDescription.length > 0) && (
                      <>
                        <div className='title2'>AI SAYS</div>
                        <ColorCircleList colors={colorDescription} />

                        {/* <div className='title2'>METADATA SAYS</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["metadata"] === "yes") ? "success" : "secondary"} style={{ cursor: "unset", outline: "unset" }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div>
                        <div className='title2'>USER SAYS</div>
                        <div className='btnsDiv'>
                          {
                            Object.keys(tagsDescription[curTag][curTag]).map((key) => (
                              <Button key={key} variant={(tagsDescription[curTag][curTag][key]["user"] === "yes") ? "success" : "secondary"} onClick={() => { updateTagsDiscription(curTag, key) }}>
                                {key}
                              </Button>
                            ))
                          }
                        </div> */}
                      </>
                    )
                  }

                  <div className="histBtnCont mt-3">
                    <div className='mr-2 pageNoCont'>
                      <input className='pageIn' type="number" value={currDressNo} onBlur={handleCurrDressNo} onChange={handleCurrDressNoChange}
                      /> / {totalDresses}
                    </div>
                    <Button className="ml-2 mr-2" variant="dark" onClick={fetchPrevDress} disabled={!hasPrev}>
                      Prev
                    </Button>
                    <Button className="ml-2" variant="dark" onClick={fetchNextDress} disabled={!hasNext}>
                      Next
                    </Button>
                  </div>
                </>
              )
              :
              (
                <div className='initDiv'>
                  <div className="m-auto imgCont">
                    <img
                      className='imgBox'
                      src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                      alt="sbv"
                    />
                  </div>
                </div>
              )
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AnnotateCard;