import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Carousel, Container, Form, Button } from 'react-bootstrap';
import ImgAnaAnnotateCard from './ImgAnaAnnotateCard';
import './css/ImgAnaAnnotate.css';
import Loader from './Loader';
import { BASE_URL } from "../lib/common";

const ImgAnaAnnotate = () => {
    const { store, ph } = useParams();
    const firstTime = useRef(true);
    const [pandingChangeCount, setPandingChangeCount] = useState(0);
    const [imgAnaRes, setImgAnaRes] = useState({});
    const [imgAnaResp, setImgAnaResp] = useState({
        "image_list": {
            "stylised_neck_piping_detail_shift_dress_l2.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_l2.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 809653,
                "total_pixel": 2430000,
                "left_margin": 268,
                "right_margin": 199,
                "top_margin": 626,
                "bottom_margin": 1,
                "percentage_pixel": 33.319053497942384
            },
            "stylised_neck_piping_detail_shift_dress_z1.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_z1.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 676279,
                "total_pixel": 1920000,
                "left_margin": 272,
                "right_margin": 291,
                "top_margin": 158,
                "bottom_margin": 114,
                "percentage_pixel": 35.22286458333333
            },
            "stylised_neck_piping_detail_shift_dress_b2.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_b2.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 1447493,
                "total_pixel": 3244800,
                "left_margin": 421,
                "right_margin": 1,
                "top_margin": 475,
                "bottom_margin": 1,
                "percentage_pixel": 44.609621548323474
            },
            "Dresses_849d25fe-43ca-477d-bb5f-a766f28e1480.jpg": {
                "name": 'Dresses_849d25fe-43ca-477d-bb5f-a766f28e1480.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 1122499,
                "total_pixel": 2252900,
                "left_margin": 176,
                "right_margin": 19,
                "top_margin": 112,
                "bottom_margin": 62,
                "percentage_pixel": 49.8246260375516
            },
            "FI_stylised_neck_piping_detail_shift_dress_l1.jpg": {
                "name": 'FI_stylised_neck_piping_detail_shift_dress_l1.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 376140,
                "total_pixel": 2430000,
                "left_margin": 480,
                "right_margin": 383,
                "top_margin": 254,
                "bottom_margin": 558,
                "percentage_pixel": 15.479012345679012
            },
            "stylised_neck_piping_detail_shift_dress_z2.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_z2.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 766194,
                "total_pixel": 1920000,
                "left_margin": 291,
                "right_margin": 232,
                "top_margin": 88,
                "bottom_margin": 111,
                "percentage_pixel": 39.9059375
            },
            "stylised_neck_piping_detail_shift_dress_b3.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_b3.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 1121635,
                "total_pixel": 3244800,
                "left_margin": 693,
                "right_margin": 1,
                "top_margin": 378,
                "bottom_margin": 1,
                "percentage_pixel": 34.567153599605525
            },
            "stylised_neck_piping_detail_shift_dress_b1_alt.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_b1_alt.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 442430,
                "total_pixel": 3242720,
                "left_margin": 109,
                "right_margin": 912,
                "top_margin": 446,
                "bottom_margin": 596,
                "percentage_pixel": 13.643792865248926
            },
            "stylised_neck_piping_detail_shift_dress_b1.jpg": {
                "name": 'stylised_neck_piping_detail_shift_dress_b1.jpg',
                "is_front": {
                    "ai_blip2vqa": "tbd",
                    "user": "tbd"
                },
                "target_pixel": 436256,
                "total_pixel": 3244800,
                "left_margin": 569,
                "right_margin": 452,
                "top_margin": 442,
                "bottom_margin": 563,
                "percentage_pixel": 13.444773175542407
            }
        },
        "back_focused_image": "stylised_neck_piping_detail_shift_dress_z2.jpg",
        "focused_image": "stylised_neck_piping_detail_shift_dress_z2.jpg"
    });

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const fetchImgAnaRes = () => {
        const url = BASE_URL + `getImgAnaRes?store=${store}&ph=${ph}`;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setImgAnaRes(data.imgAnaRes);
            })
            .catch((error) => {
                showAlert(`Error While Fetching data:${error}`, 'red');
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        if (firstTime.current) {
            fetchImgAnaRes();
            firstTime.current = false;
        }
    }, []);


    const saveFeedback = () => {
        const url = BASE_URL + `setImgAnaRes`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ store, ph, imgAnaRes }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    showAlert(`FEEDBACK SAVED SUCCESSFULLY.`, 'lightgreen');
                    console.log("FEEDBACK SAVED SUCCESSFULLY.");
                    setPandingChangeCount(0);
                } else {
                    showAlert(`Error While Saving Feedback: ${data}`, 'red');
                    console.log("ERROR WHILE SAVING FEEDBACK : ", data);
                }
            })
            .catch((error) => {
                showAlert(`Error setting dress description: ${error}`, 'red');
                console.log('Error setting dress description:', error);
            });
    };

    const updateUserField = (imageName) => {
        console.log("Clicked imageName=", imageName);
        setImgAnaRes(prevState => ({
            ...prevState,
            image_list: {
                ...prevState.image_list,
                [imageName]: {
                    ...prevState.image_list[imageName],
                    is_front: {
                        ...prevState.image_list[imageName].is_front,
                        user: prevState.image_list[imageName].is_front.user === 'yes' ? 'no' : 'yes',
                    },
                },
            },
        }));
        setPandingChangeCount(pandingChangeCount + 1);
    };


    const handleButtonClick = (imageName) => {
        updateUserField(imageName);
    };

    return (
        <div className='imgAnaAnnotate'>
            <Alerts />
            <div className='title2'>PRODUCT HANDLE : {ph}
                <Button className="ml-2" variant="primary" disabled={(pandingChangeCount > 0) ? false : true} onClick={saveFeedback}>
                    SAVE FEEDBACK
                </Button>
            </div>
            {
                (imgAnaRes && imgAnaRes?.image_list) ? (
                    <>
                        {
                            Object.keys(imgAnaRes.image_list).map((imgName, idx) => (
                                <ImgAnaAnnotateCard key={idx} store={store} ph={ph} imgObj={imgAnaRes.image_list[imgName]} handleButtonClick={handleButtonClick} />
                                // <ImgAnaAnnotateCard key={idx} store={store} ph={'stylised-neck-piping-detail-shift-dress-red'} imgObj={imgAnaRes.image_list[imgName]} handleButtonClick={handleButtonClick} />
                            ))
                        }
                    </>
                ) : (
                    <div className='initDiv'>
                        <div className="m-auto imgCont">
                            <img
                                className='imgBox'
                                src="https://img.freepik.com/free-vector/file-searching-concept-illustration_114360-4125.jpg?size=626&ext=jpg&ga=GA1.2.1921206711.1694439131&semt=ais"
                                alt="sbv"
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ImgAnaAnnotate;