//Function to send application logs to server and also log them in the console

import axios from "axios";
import moment from "moment";
import DownloadStatus from "./DownloadStatus";

const logger = (log, filename = "") => {
  let log_line = "";
  if (typeof log === "object") {
    log_line = JSON.stringify(log);
  } else {
    log_line = log;
  }

  if (DownloadStatus.getClientLog2Server() === 0) {
    console.log(filename + " : " + log_line);
    return;
  }

  let ts = moment().format("DD-MMM-YYYY-h-mm-ss-SSS");
  console.log(filename + " : " + log_line + " - " + ts);
  log_line = ts + "::" + filename + " : " + log_line;
  const url = "http://64.227.152.133:5000/client-log?log_line=" + log_line + "&devname=mk2";
  axios.get(url).catch(function (error) {
    console.log("Error: Cannot send client log to server");
    console.log(error);
  });
};

export default logger;
