import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Spinner animation="grow" role="status" className="spinner-lg">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loader;